<template>
	<v-form
		ref="emailTemplateForm"
		v-model.trim="formValid"
		lazy-validation
		v-on:submit.stop.prevent="updateEmailTemplateSetting"
	>
		<v-sheet>
			<v-layout class="py-3 px-3">
				<v-flex class="py-0">
					<div class="sub-heading primary--text">Email Template</div>
				</v-flex>
				<v-spacer></v-spacer>
				<v-flex class="py-0 d-flex justify-content-end">
					<v-btn tile depressed class="ml-2" @click="$router.go(-1)">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="blue darken-4 white--text"
						class="ml-2"
						v-on:click="updateEmailTemplateSetting"
						>Save</v-btn
					>
				</v-flex>
			</v-layout>
			<v-row>
				<v-col md="8" lg="9" class="email-template-scroll">
					<div class="mb-5 d-flex">
						<div class="w-200 my-auto px-2">
							<label for="" class="fw-500">Template Name</label>
						</div>
						<v-flex>
							<v-text-field
								hide-details
								outlined
								readonly
								:disabled="pageLoading"
								:loading="pageLoading"
								placeholder="Template Name"
								v-model="emailTemplate.template"
							></v-text-field>
						</v-flex>
					</div>
					<div class="mb-5 d-flex">
						<div class="w-200 my-auto px-2">
							<label for="" class="fw-500">Roles</label>
						</div>
						<v-flex>
							<AutoCompleteInput
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								id="roles"
								placeholder="Roles"
								v-model="emailTemplate.authorized"
								:items="role_items"
								hide-top-margin
								item-text="text"
								item-value="value"
								multiple
								return-object
							></AutoCompleteInput>
						</v-flex>
					</div>
					<template v-if="emailTemplate.authorized && emailTemplate.authorized.length">
						<v-tabs color="blue darken-4" v-model="role_tab" background-color="transparent">
							<v-tab
								v-for="(row, index) in emailTemplate.authorized"
								:href="`#${row.value}`"
								:key="`tab-${index}`"
							>
								{{ row.text }}
							</v-tab>
						</v-tabs>
						<v-tabs-items v-model="role_tab">
							<v-tab-item
								v-for="(row, index) in emailTemplate.authorized"
								:value="row.value"
								:key="`tab-item-${index}`"
							>
								<div class="mx-4 my-4">
									<table width="100%">
										<tr>
											<td width="20%">
												<label :for="`mail-subject-${index}`" class="fw-500">Subject</label>
											</td>
											<td width="80%" class="py-2">
												<v-text-field
													:id="`mail-subject-${index}`"
													hide-details
													outlined
													:disabled="pageLoading"
													:loading="pageLoading"
													placeholder="Subject"
													v-model="row['m_subject']"
												></v-text-field>
											</td>
										</tr>
										<tr>
											<td width="20%" valign="top">
												<br />
												<label :for="`mail-body-${index}`" class="fw-500">Body</label>
											</td>
											<td width="80%" class="py-2">
												<ckeditor
													:id="`mail-body-${index}`"
													:editor="editor"
													:disabled="pageLoading"
													:loading="pageLoading"
													v-model="row['m_body']"
													:config="editorConfig"
												></ckeditor>
											</td>
										</tr>
										<tr>
											<td width="20%">
												<label :for="`notification-title-${index}`" class="fw-500">Notification Title</label>
											</td>
											<td width="80%" class="py-2">
												<v-text-field
													:id="`notification-title-${index}`"
													hide-details
													outlined
													:disabled="pageLoading"
													:loading="pageLoading"
													placeholder="Notification Title"
													v-model="row['n_title']"
												></v-text-field>
											</td>
										</tr>
										<tr>
											<td width="20%">
												<label :for="`notification-description-${index}`" class="fw-500"
													>Notification Description</label
												>
											</td>
											<td width="80%" class="py-2">
												<v-text-field
													:id="`notification-description-${index}`"
													hide-details
													outlined
													:disabled="pageLoading"
													:loading="pageLoading"
													placeholder="Notification Description"
													v-model="row['n_desc']"
												></v-text-field>
											</td>
										</tr>
									</table>
								</div>
							</v-tab-item>
						</v-tabs-items>
					</template>
					<template v-else-if="pageLoading">
						<table width="100%" style="table-layout: fixed">
							<tbody>
								<tr>
									<td colspan="3" height="100px"></td>
								</tr>
								<tr>
									<td width="33.33%"></td>
									<td width="33.33%">
										<v-progress-linear
											color="blue darken-4"
											indeterminate
											rounded
											height="6"
										></v-progress-linear>
									</td>
									<td width="33.33%"></td>
								</tr>
							</tbody>
						</table>
					</template>
				</v-col>
				<v-col md="4" lg="3" class="email-template-scroll">
					<div class="sub-heading primary--text mb-3">Variables</div>
					<div class="fw-500 mb-3 pointer" v-for="(row, index) in availableVariables" :key="index">
						<v-tooltip top content-class="custom-top-tooltip">
							<template v-slot:activator="{ on, attrs }">
								<span
									v-on="on"
									class="cursor-pointer"
									ref="text"
									v-on:click="copyText(index, row)"
									v-bind="attrs"
									v-text="`${row}`"
								></span>
							</template>
							<span>Click to Copy</span>
						</v-tooltip>
					</div>
				</v-col>
			</v-row>
		</v-sheet>
	</v-form>
</template>
<script>
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toSafeInteger } from "lodash";
import { GetEmailTemplate, UpdateEmailTemplates } from "@/core/lib/setting.lib";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";

export default {
	name: "email-template-update",
	data() {
		return {
			formValid: true,
			template_name: null,
			subject: null,
			templateId: 0,
			emailTemplate: {
				customer_body: "",
				engineer_body: "",
				admin_body: "",
				mobile_app: null,
				notification: null,
				notification_desc: null,
				template: null,
				subject: null,
			},
			role_tab: null,
			in_app_notification: null,
			editor: ClassicEditor,
			availableVariables: new Array(),
			editorConfig: {
				// The configuration of the editor.
			},
			pageLoading: false,
			email_content: {},
			role_items: [
				{ text: "Alcon", value: "alcon" },
				{ text: "Engineer", value: "engineer" },
				{ text: "Subcon", value: "subcon" },
				{ text: "Caltek Admin", value: "caltek-admin" },
				{ text: "Super Admin", value: "super-admin" },
			],
		};
	},
	components: {
		AutoCompleteInput,
	},
	methods: {
		selectText(element) {
			let range;
			if (document.selection) {
				// IE
				range = document.body.createTextRange();
				range.moveToElementText(element);
				range.select();
			} else if (window.getSelection) {
				range = document.createRange();
				range.selectNode(element);
				window.getSelection().removeAllRanges();
				window.getSelection().addRange(range);
			}
		},
		copyText(index) {
			try {
				this.selectText(this.$refs.text[index]);
				document.execCommand("copy");
				window.getSelection().removeAllRanges();
			} catch (error) {
				this.logError(error);
			}
		},
		getOneEmailTemplateSetting() {
			const _this = this;
			_this.pageLoading = true;
			GetEmailTemplate(_this.templateId)
				.then((data) => {
					_this.availableVariables = data.variables || new Array();
					_this.group = data.group || null;
					_this.emailTemplate = new Object({
						authorized: data.authorized,
						template: data.template,
					});
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		updateEmailTemplateSetting() {
			const _this = this;
			if (!_this.$refs.emailTemplateForm.validate()) {
				return false;
			}
			const formData = {
				template: _this.emailTemplate.template,
				authorized: _this.emailTemplate.authorized,
			};
			_this.formLoading = true;
			UpdateEmailTemplates(_this.templateId, formData)
				.then(() => {
					_this.pageCreated = true;
					_this.$router.go(-1);
				})
				.catch((error) => {
					_this.logError(error);
				})
				.finally(() => {
					_this.formLoading = false;
					_this.$router.push({ name: "setting", query: { tab: "email_template" } });
				});
		},
	},
	mounted() {
		const _this = this;
		_this.templateId = toSafeInteger(_this.$route.params.id);
		if (_this.templateId > 0) {
			_this.getOneEmailTemplateSetting();
		}
	},
};
</script>
